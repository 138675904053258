import { Table, TableBody, TableCell, TableRow } from "@/components/ui/table";
import { RemoveUserDialog } from "./remove-user";
import { UsersViewType } from "./types";

import dayjs from "dayjs";
import { ChangeUserRole } from "./change-user-role";
import Gravatar from "react-gravatar";

type UsersTableProps = {
  usersView: UsersViewType;
  users: any[];
};

export const UserTable = ({ usersView, users }: UsersTableProps) => {
  if (!users.length) {
    return <div className="text-center text-foreground/60">No users found</div>;
  }

  return (
    <div className="w-full rounded-xl shadow-md overflow-hidden">
      <Table>
        <TableBody>
          {users.map((user) => (
            <TableRow key={user.user_id}>
              <TableCell className="flex items-center space-x-4 py-4">
                <Gravatar
                  email={user.email}
                  className="h-10 w-10 rounded-full"
                />
                <div>
                  <div className="font-semibold">
                    {user.profile_metadata?.first_name || "-"}{" "}
                    {user.profile_metadata?.last_name || "-"}
                  </div>
                  <div className="text-sm text-foreground/50">{user.email}</div>
                </div>
              </TableCell>

              <TableCell className="text-right">
                <div className="flex justify-end items-center space-x-3">
                  {usersView === UsersViewType.EXISTING ? (
                    <TableCell className="text-right">
                      <div className="text-sm text-primary/50">
                        Joined date:{" "}
                        {dayjs(user.joinedDate).format("DD MMM YYYY")}
                      </div>
                    </TableCell>
                  ) : null}

                  <TableCell className="text-right align-middle">
                    <div className="flex justify-end pr-3">
                      <ChangeUserRole
                        disabled={usersView === UsersViewType.PENDING}
                        userId={user.user_id}
                        userEmail={user.email}
                        role={user.role_name}
                      />
                    </div>
                  </TableCell>

                  <TableCell className="text-right">
                    <RemoveUserDialog
                      userId={user.user_id}
                      userEmail={user.email}
                      usersViewType={usersView}
                    />
                  </TableCell>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};
