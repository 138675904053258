import DashboardTemplates from "@/pages/DashboardTemplates";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/$orgId/(dashboard)/dashboard/templates")(
  {
    component: () => <DashboardTemplates />,
    meta: () => [
      { title: 'Templates' }
    ]
  },
);
