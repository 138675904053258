import { ToastContainer as Toastify } from "react-toastify";

const contextClass = {
  success: "text-primary [&_svg]:fill-primary",
  error: "",
  info: "",
  warning: "",
  default: "",
  dark: "",
};

export const ToastContainer = () => {
  return (
    <Toastify
      toastClassName={(context) => {
        const newClassName =
          " relative bg-background flex min-h-10 shadow shadow-lg my-3 justify-between overflow-hidden cursor-pointer";

        if (context?.type === "success") {
          return newClassName + " " + contextClass.success;
        }

        return context?.defaultClassName + newClassName;
      }}
      bodyClassName={() => "text-sm flex p-4 w-full"}
      progressClassName={(context) => {
        if (context?.type === "success") {
          return context?.defaultClassName + " !bg-primary ";
        }

        return context?.defaultClassName + "";
      }}
    />
  );
};
