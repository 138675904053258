import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { persist } from 'zustand/middleware'

import { isTokenExpired } from "./utils";
import { toast } from "react-toastify";

interface UserState {
  orgLimits: null | {
    perUserStorageCount: number
  },
  user: null | {
    email: string;
    token: string;
  },
  isLoggedIn: boolean;

  setUser: (email: string, token: string) => void;
  clearAuth: () => void;
}

export const useUserStore = create<UserState>()(
  persist(
    immer((set) => ({
      isLoggedIn: false,
      user: null,
      orgLimits: null,

      // Set the token and save it to localStorage
      setUser: (email: string, token: string) =>
        set((state) => {
          state.user = { email, token };
          // Set the user as logged in
          state.isLoggedIn = true;
        }),

      // Set the organization ID and save it to localStorage
      clearAuth: () =>
        set((state) => {
          state.user = null;
          state.isLoggedIn = false;
        }),
    })),
    {
      name: 'auth-storage',
      // st which fields to store in localStorage
      partialize: (state) => ({ user: state.user, isLoggedIn: state.isLoggedIn }),
      onRehydrateStorage: () => {
        return (state) => {
          if (state && state.user?.token && isTokenExpired(state.user.token)) {
            toast.info("Session expired, please log in again");
            console.info("Token expired, clearing auth state");
            state.clearAuth();
          }

          return state;
        }
      }
    }
  ),
);

