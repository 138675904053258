import { Sidebar } from "@/components/ui/sidebar";
import { DashboardSidebarFooter } from "./sidebar-footer";
import { DashboardSidebarContent } from "./sidebar-content";
import { DashboardSidebarHeader } from "./sidebar-header";

export const DashboardSidebar = () => {
  return (
    <Sidebar>
      <DashboardSidebarHeader />
      <DashboardSidebarContent />
      <DashboardSidebarFooter />
    </Sidebar>
  )
}

