/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo, useState } from "react";
import { UserManagementHeader } from "./header";
import { UsersViewType, UsersSortKeys } from "./types";
import { UserTable } from "./users-table";
import { useGetOrgUsers } from "@/services/user-management";
import { Spinner } from "@/components/spinner";
import { Virtuoso } from "react-virtuoso";

export const DashboardUserManagement = () => {
  const [usersView, onUsersViewChange] = useState(UsersViewType.EXISTING);
  const [searchTerm, setSearchTerm] = useState("");
  const [userSort, setUserSort] = useState(UsersSortKeys.CREATED_AT_DESC);

  const {
    data: allOrgUsers,
    isLoading: isAllOrgUsersLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetOrgUsers(searchTerm, userSort);

  const allUsers = allOrgUsers?.pages.flatMap((page) => page) || [];

  const filteredUsers = useMemo(() => {
    const matchStatus = usersView === UsersViewType.EXISTING ? "active" : "invited";
    return allUsers.filter((user: any) => user.status === matchStatus);
  }, [allUsers, usersView]);

  return (
    <div className="flex flex-col gap-5">
      <UserManagementHeader
        usersViewType={usersView}
        onUsersViewChange={onUsersViewChange}
        onSearchChange={setSearchTerm}
        usersViewSortType={userSort}
        onUsersViewSortChange={setUserSort}
      />

      {isAllOrgUsersLoading ? (
        <Spinner />
      ) : (
        <Virtuoso
          useWindowScroll
          data={filteredUsers}
          endReached={() => {
            if (hasNextPage) {
              fetchNextPage();
            }
          }}
          itemContent={(_index, user) => (
            <UserTable key={user.user_id} usersView={usersView} users={[user]} />
          )}
          components={{
            Footer: () => (
              <div className="text-center py-8">
                {isFetchingNextPage ? <Spinner /> : hasNextPage ? null : <p className="text-sm text-foreground/50">End of list.</p>}
              </div>
            ),
          }}
        />
      )}
    </div>
  );
};
