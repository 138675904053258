import { useForm } from "react-hook-form";
import { Card, CardContent } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import { useUserStore } from "@/store/UserStore";
import Gravatar from "react-gravatar";
import { useUpdateUserProfile } from "@/services/profile";

import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { toast } from "react-toastify";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { useActiveOrgProfile } from "@/hooks/useActiveOrgProfile";
import { useEffect } from "react";
import { capitalize } from "@/helpers/string";

const FormSchema = z.object({
  firstName: z.string().max(50, {
    message: "first name must be less than 50 characters.",
  }),
  lastName: z.string().max(50, {
    message: "first name must be less than 50 characters.",
  }),
});

export const MyProfileForm = () => {
  const { user } = useUserStore();
  const { data } = useActiveOrgProfile();

  const firstName = data?.user_profile?.first_name || "";
  const lastName = data?.user_profile?.last_name || "";

  const { mutateAsync, isPending } = useUpdateUserProfile({
    onSuccess: () => {
      toast.success("Profile updated successfully");
    },
    onError: () => {
      toast.error("Profile update failed");
    },
  });

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      firstName: "",
      lastName: "",
    },
  });

  useEffect(() => {
    if (data?.user_profile) {
      form.reset({
        firstName: data.user_profile?.first_name || "",
        lastName: data.user_profile?.last_name || "",
      });
    }
  }, [data, form]);

  async function onSubmit(data: z.infer<typeof FormSchema>) {
    await mutateAsync({
      firstName: capitalize(data.firstName),
      lastName: capitalize(data.lastName),
    });
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-8"
      >
        <Card className="border-primary/20">
          <CardContent className="px-14 py-12 flex flex-col gap-4">
            <div className="mx-auto mb-8">
              <Gravatar
                email={user?.email}
                className="w-16 h-16 bg-primary/30 rounded-full "
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="email" className="text-sm font-medium">
                Email
              </Label>
              <Input
                disabled
                type="email"
                id="email"
                value={user?.email}
                className="w-full"
              />
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-2">
                <FormField
                  control={form.control}
                  name="firstName"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>First Name</FormLabel>
                      <FormControl>
                        <Input
                          type="text"
                          placeholder={firstName}
                          className="w-full"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="space-y-2">
                <FormField
                  control={form.control}
                  name="lastName"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Last Name</FormLabel>
                      <FormControl>
                        <Input
                          type="text"
                          placeholder={lastName}
                          className="w-full"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>
          </CardContent>
        </Card>

        <Button type="submit" className="w-[150px] mx-auto">
          {isPending ? "Saving..." : "Save"}
        </Button>
      </form>
    </Form>
  );
};
