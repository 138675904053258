// import { Moon, Sun } from "lucide-react"

import { useTheme } from "@/components/theme-provider";
import { Children, cloneElement, ReactElement } from "react";

interface Props {
  children: ReactElement;
}

export const ThemeToggle = ({ children }: Props) => {
  const { setTheme, theme } = useTheme();

  const element = Children.only(children);

  return cloneElement(element, {
    onClick: () => setTheme(theme === "dark" ? "light" : "dark"),
  });
};
