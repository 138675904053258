/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as R404Import } from './routes/404'
import { Route as IndexImport } from './routes/index'
import { Route as OrgIddashboardDashboardImport } from './routes/$orgId/(dashboard)/dashboard'
import { Route as OrgIddashboardDashboardUsersImport } from './routes/$orgId/(dashboard)/dashboard.users'
import { Route as OrgIddashboardDashboardTemplatesImport } from './routes/$orgId/(dashboard)/dashboard.templates'
import { Route as OrgIddashboardDashboardStorageImport } from './routes/$orgId/(dashboard)/dashboard.storage'
import { Route as OrgIddashboardDashboardSshkeysImport } from './routes/$orgId/(dashboard)/dashboard.sshkeys'
import { Route as OrgIddashboardDashboardServersImport } from './routes/$orgId/(dashboard)/dashboard.servers'
import { Route as OrgIddashboardDashboardProfileImport } from './routes/$orgId/(dashboard)/dashboard.profile'
import { Route as OrgIddashboardDashboardTemplatesNewImport } from './routes/$orgId/(dashboard)/dashboard.templates_.new'
import { Route as OrgIddashboardDashboardServersNewImport } from './routes/$orgId/(dashboard)/dashboard.servers_.new'

// Create Virtual Routes

const SettingsLazyImport = createFileRoute('/settings')()
const LoginLazyImport = createFileRoute('/login')()
const ClustersLazyImport = createFileRoute('/clusters')()
const AboutLazyImport = createFileRoute('/about')()

// Create/Update Routes

const SettingsLazyRoute = SettingsLazyImport.update({
  id: '/settings',
  path: '/settings',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/settings.lazy').then((d) => d.Route))

const LoginLazyRoute = LoginLazyImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/login.lazy').then((d) => d.Route))

const ClustersLazyRoute = ClustersLazyImport.update({
  id: '/clusters',
  path: '/clusters',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/clusters.lazy').then((d) => d.Route))

const AboutLazyRoute = AboutLazyImport.update({
  id: '/about',
  path: '/about',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/about.lazy').then((d) => d.Route))

const R404Route = R404Import.update({
  id: '/404',
  path: '/404',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const OrgIddashboardDashboardRoute = OrgIddashboardDashboardImport.update({
  id: '/$orgId/(dashboard)/dashboard',
  path: '/$orgId/dashboard',
  getParentRoute: () => rootRoute,
} as any)

const OrgIddashboardDashboardUsersRoute =
  OrgIddashboardDashboardUsersImport.update({
    id: '/users',
    path: '/users',
    getParentRoute: () => OrgIddashboardDashboardRoute,
  } as any)

const OrgIddashboardDashboardTemplatesRoute =
  OrgIddashboardDashboardTemplatesImport.update({
    id: '/templates',
    path: '/templates',
    getParentRoute: () => OrgIddashboardDashboardRoute,
  } as any)

const OrgIddashboardDashboardStorageRoute =
  OrgIddashboardDashboardStorageImport.update({
    id: '/storage',
    path: '/storage',
    getParentRoute: () => OrgIddashboardDashboardRoute,
  } as any)

const OrgIddashboardDashboardSshkeysRoute =
  OrgIddashboardDashboardSshkeysImport.update({
    id: '/sshkeys',
    path: '/sshkeys',
    getParentRoute: () => OrgIddashboardDashboardRoute,
  } as any)

const OrgIddashboardDashboardServersRoute =
  OrgIddashboardDashboardServersImport.update({
    id: '/servers',
    path: '/servers',
    getParentRoute: () => OrgIddashboardDashboardRoute,
  } as any)

const OrgIddashboardDashboardProfileRoute =
  OrgIddashboardDashboardProfileImport.update({
    id: '/profile',
    path: '/profile',
    getParentRoute: () => OrgIddashboardDashboardRoute,
  } as any)

const OrgIddashboardDashboardTemplatesNewRoute =
  OrgIddashboardDashboardTemplatesNewImport.update({
    id: '/templates_/new',
    path: '/templates/new',
    getParentRoute: () => OrgIddashboardDashboardRoute,
  } as any)

const OrgIddashboardDashboardServersNewRoute =
  OrgIddashboardDashboardServersNewImport.update({
    id: '/servers_/new',
    path: '/servers/new',
    getParentRoute: () => OrgIddashboardDashboardRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/404': {
      id: '/404'
      path: '/404'
      fullPath: '/404'
      preLoaderRoute: typeof R404Import
      parentRoute: typeof rootRoute
    }
    '/about': {
      id: '/about'
      path: '/about'
      fullPath: '/about'
      preLoaderRoute: typeof AboutLazyImport
      parentRoute: typeof rootRoute
    }
    '/clusters': {
      id: '/clusters'
      path: '/clusters'
      fullPath: '/clusters'
      preLoaderRoute: typeof ClustersLazyImport
      parentRoute: typeof rootRoute
    }
    '/login': {
      id: '/login'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof LoginLazyImport
      parentRoute: typeof rootRoute
    }
    '/settings': {
      id: '/settings'
      path: '/settings'
      fullPath: '/settings'
      preLoaderRoute: typeof SettingsLazyImport
      parentRoute: typeof rootRoute
    }
    '/$orgId/(dashboard)/dashboard': {
      id: '/$orgId/(dashboard)/dashboard'
      path: '/$orgId/dashboard'
      fullPath: '/$orgId/dashboard'
      preLoaderRoute: typeof OrgIddashboardDashboardImport
      parentRoute: typeof rootRoute
    }
    '/$orgId/(dashboard)/dashboard/profile': {
      id: '/$orgId/(dashboard)/dashboard/profile'
      path: '/profile'
      fullPath: '/$orgId/dashboard/profile'
      preLoaderRoute: typeof OrgIddashboardDashboardProfileImport
      parentRoute: typeof OrgIddashboardDashboardImport
    }
    '/$orgId/(dashboard)/dashboard/servers': {
      id: '/$orgId/(dashboard)/dashboard/servers'
      path: '/servers'
      fullPath: '/$orgId/dashboard/servers'
      preLoaderRoute: typeof OrgIddashboardDashboardServersImport
      parentRoute: typeof OrgIddashboardDashboardImport
    }
    '/$orgId/(dashboard)/dashboard/sshkeys': {
      id: '/$orgId/(dashboard)/dashboard/sshkeys'
      path: '/sshkeys'
      fullPath: '/$orgId/dashboard/sshkeys'
      preLoaderRoute: typeof OrgIddashboardDashboardSshkeysImport
      parentRoute: typeof OrgIddashboardDashboardImport
    }
    '/$orgId/(dashboard)/dashboard/storage': {
      id: '/$orgId/(dashboard)/dashboard/storage'
      path: '/storage'
      fullPath: '/$orgId/dashboard/storage'
      preLoaderRoute: typeof OrgIddashboardDashboardStorageImport
      parentRoute: typeof OrgIddashboardDashboardImport
    }
    '/$orgId/(dashboard)/dashboard/templates': {
      id: '/$orgId/(dashboard)/dashboard/templates'
      path: '/templates'
      fullPath: '/$orgId/dashboard/templates'
      preLoaderRoute: typeof OrgIddashboardDashboardTemplatesImport
      parentRoute: typeof OrgIddashboardDashboardImport
    }
    '/$orgId/(dashboard)/dashboard/users': {
      id: '/$orgId/(dashboard)/dashboard/users'
      path: '/users'
      fullPath: '/$orgId/dashboard/users'
      preLoaderRoute: typeof OrgIddashboardDashboardUsersImport
      parentRoute: typeof OrgIddashboardDashboardImport
    }
    '/$orgId/(dashboard)/dashboard/servers_/new': {
      id: '/$orgId/(dashboard)/dashboard/servers_/new'
      path: '/servers/new'
      fullPath: '/$orgId/dashboard/servers/new'
      preLoaderRoute: typeof OrgIddashboardDashboardServersNewImport
      parentRoute: typeof OrgIddashboardDashboardImport
    }
    '/$orgId/(dashboard)/dashboard/templates_/new': {
      id: '/$orgId/(dashboard)/dashboard/templates_/new'
      path: '/templates/new'
      fullPath: '/$orgId/dashboard/templates/new'
      preLoaderRoute: typeof OrgIddashboardDashboardTemplatesNewImport
      parentRoute: typeof OrgIddashboardDashboardImport
    }
  }
}

// Create and export the route tree

interface OrgIddashboardDashboardRouteChildren {
  OrgIddashboardDashboardProfileRoute: typeof OrgIddashboardDashboardProfileRoute
  OrgIddashboardDashboardServersRoute: typeof OrgIddashboardDashboardServersRoute
  OrgIddashboardDashboardSshkeysRoute: typeof OrgIddashboardDashboardSshkeysRoute
  OrgIddashboardDashboardStorageRoute: typeof OrgIddashboardDashboardStorageRoute
  OrgIddashboardDashboardTemplatesRoute: typeof OrgIddashboardDashboardTemplatesRoute
  OrgIddashboardDashboardUsersRoute: typeof OrgIddashboardDashboardUsersRoute
  OrgIddashboardDashboardServersNewRoute: typeof OrgIddashboardDashboardServersNewRoute
  OrgIddashboardDashboardTemplatesNewRoute: typeof OrgIddashboardDashboardTemplatesNewRoute
}

const OrgIddashboardDashboardRouteChildren: OrgIddashboardDashboardRouteChildren =
  {
    OrgIddashboardDashboardProfileRoute: OrgIddashboardDashboardProfileRoute,
    OrgIddashboardDashboardServersRoute: OrgIddashboardDashboardServersRoute,
    OrgIddashboardDashboardSshkeysRoute: OrgIddashboardDashboardSshkeysRoute,
    OrgIddashboardDashboardStorageRoute: OrgIddashboardDashboardStorageRoute,
    OrgIddashboardDashboardTemplatesRoute:
      OrgIddashboardDashboardTemplatesRoute,
    OrgIddashboardDashboardUsersRoute: OrgIddashboardDashboardUsersRoute,
    OrgIddashboardDashboardServersNewRoute:
      OrgIddashboardDashboardServersNewRoute,
    OrgIddashboardDashboardTemplatesNewRoute:
      OrgIddashboardDashboardTemplatesNewRoute,
  }

const OrgIddashboardDashboardRouteWithChildren =
  OrgIddashboardDashboardRoute._addFileChildren(
    OrgIddashboardDashboardRouteChildren,
  )

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '/404': typeof R404Route
  '/about': typeof AboutLazyRoute
  '/clusters': typeof ClustersLazyRoute
  '/login': typeof LoginLazyRoute
  '/settings': typeof SettingsLazyRoute
  '/$orgId/dashboard': typeof OrgIddashboardDashboardRouteWithChildren
  '/$orgId/dashboard/profile': typeof OrgIddashboardDashboardProfileRoute
  '/$orgId/dashboard/servers': typeof OrgIddashboardDashboardServersRoute
  '/$orgId/dashboard/sshkeys': typeof OrgIddashboardDashboardSshkeysRoute
  '/$orgId/dashboard/storage': typeof OrgIddashboardDashboardStorageRoute
  '/$orgId/dashboard/templates': typeof OrgIddashboardDashboardTemplatesRoute
  '/$orgId/dashboard/users': typeof OrgIddashboardDashboardUsersRoute
  '/$orgId/dashboard/servers/new': typeof OrgIddashboardDashboardServersNewRoute
  '/$orgId/dashboard/templates/new': typeof OrgIddashboardDashboardTemplatesNewRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '/404': typeof R404Route
  '/about': typeof AboutLazyRoute
  '/clusters': typeof ClustersLazyRoute
  '/login': typeof LoginLazyRoute
  '/settings': typeof SettingsLazyRoute
  '/$orgId/dashboard': typeof OrgIddashboardDashboardRouteWithChildren
  '/$orgId/dashboard/profile': typeof OrgIddashboardDashboardProfileRoute
  '/$orgId/dashboard/servers': typeof OrgIddashboardDashboardServersRoute
  '/$orgId/dashboard/sshkeys': typeof OrgIddashboardDashboardSshkeysRoute
  '/$orgId/dashboard/storage': typeof OrgIddashboardDashboardStorageRoute
  '/$orgId/dashboard/templates': typeof OrgIddashboardDashboardTemplatesRoute
  '/$orgId/dashboard/users': typeof OrgIddashboardDashboardUsersRoute
  '/$orgId/dashboard/servers/new': typeof OrgIddashboardDashboardServersNewRoute
  '/$orgId/dashboard/templates/new': typeof OrgIddashboardDashboardTemplatesNewRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/404': typeof R404Route
  '/about': typeof AboutLazyRoute
  '/clusters': typeof ClustersLazyRoute
  '/login': typeof LoginLazyRoute
  '/settings': typeof SettingsLazyRoute
  '/$orgId/(dashboard)/dashboard': typeof OrgIddashboardDashboardRouteWithChildren
  '/$orgId/(dashboard)/dashboard/profile': typeof OrgIddashboardDashboardProfileRoute
  '/$orgId/(dashboard)/dashboard/servers': typeof OrgIddashboardDashboardServersRoute
  '/$orgId/(dashboard)/dashboard/sshkeys': typeof OrgIddashboardDashboardSshkeysRoute
  '/$orgId/(dashboard)/dashboard/storage': typeof OrgIddashboardDashboardStorageRoute
  '/$orgId/(dashboard)/dashboard/templates': typeof OrgIddashboardDashboardTemplatesRoute
  '/$orgId/(dashboard)/dashboard/users': typeof OrgIddashboardDashboardUsersRoute
  '/$orgId/(dashboard)/dashboard/servers_/new': typeof OrgIddashboardDashboardServersNewRoute
  '/$orgId/(dashboard)/dashboard/templates_/new': typeof OrgIddashboardDashboardTemplatesNewRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/404'
    | '/about'
    | '/clusters'
    | '/login'
    | '/settings'
    | '/$orgId/dashboard'
    | '/$orgId/dashboard/profile'
    | '/$orgId/dashboard/servers'
    | '/$orgId/dashboard/sshkeys'
    | '/$orgId/dashboard/storage'
    | '/$orgId/dashboard/templates'
    | '/$orgId/dashboard/users'
    | '/$orgId/dashboard/servers/new'
    | '/$orgId/dashboard/templates/new'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/404'
    | '/about'
    | '/clusters'
    | '/login'
    | '/settings'
    | '/$orgId/dashboard'
    | '/$orgId/dashboard/profile'
    | '/$orgId/dashboard/servers'
    | '/$orgId/dashboard/sshkeys'
    | '/$orgId/dashboard/storage'
    | '/$orgId/dashboard/templates'
    | '/$orgId/dashboard/users'
    | '/$orgId/dashboard/servers/new'
    | '/$orgId/dashboard/templates/new'
  id:
    | '__root__'
    | '/'
    | '/404'
    | '/about'
    | '/clusters'
    | '/login'
    | '/settings'
    | '/$orgId/(dashboard)/dashboard'
    | '/$orgId/(dashboard)/dashboard/profile'
    | '/$orgId/(dashboard)/dashboard/servers'
    | '/$orgId/(dashboard)/dashboard/sshkeys'
    | '/$orgId/(dashboard)/dashboard/storage'
    | '/$orgId/(dashboard)/dashboard/templates'
    | '/$orgId/(dashboard)/dashboard/users'
    | '/$orgId/(dashboard)/dashboard/servers_/new'
    | '/$orgId/(dashboard)/dashboard/templates_/new'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  R404Route: typeof R404Route
  AboutLazyRoute: typeof AboutLazyRoute
  ClustersLazyRoute: typeof ClustersLazyRoute
  LoginLazyRoute: typeof LoginLazyRoute
  SettingsLazyRoute: typeof SettingsLazyRoute
  OrgIddashboardDashboardRoute: typeof OrgIddashboardDashboardRouteWithChildren
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  R404Route: R404Route,
  AboutLazyRoute: AboutLazyRoute,
  ClustersLazyRoute: ClustersLazyRoute,
  LoginLazyRoute: LoginLazyRoute,
  SettingsLazyRoute: SettingsLazyRoute,
  OrgIddashboardDashboardRoute: OrgIddashboardDashboardRouteWithChildren,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/404",
        "/about",
        "/clusters",
        "/login",
        "/settings",
        "/$orgId/(dashboard)/dashboard"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/404": {
      "filePath": "404.tsx"
    },
    "/about": {
      "filePath": "about.lazy.tsx"
    },
    "/clusters": {
      "filePath": "clusters.lazy.tsx"
    },
    "/login": {
      "filePath": "login.lazy.tsx"
    },
    "/settings": {
      "filePath": "settings.lazy.tsx"
    },
    "/$orgId/(dashboard)/dashboard": {
      "filePath": "$orgId/(dashboard)/dashboard.tsx",
      "children": [
        "/$orgId/(dashboard)/dashboard/profile",
        "/$orgId/(dashboard)/dashboard/servers",
        "/$orgId/(dashboard)/dashboard/sshkeys",
        "/$orgId/(dashboard)/dashboard/storage",
        "/$orgId/(dashboard)/dashboard/templates",
        "/$orgId/(dashboard)/dashboard/users",
        "/$orgId/(dashboard)/dashboard/servers_/new",
        "/$orgId/(dashboard)/dashboard/templates_/new"
      ]
    },
    "/$orgId/(dashboard)/dashboard/profile": {
      "filePath": "$orgId/(dashboard)/dashboard.profile.tsx",
      "parent": "/$orgId/(dashboard)/dashboard"
    },
    "/$orgId/(dashboard)/dashboard/servers": {
      "filePath": "$orgId/(dashboard)/dashboard.servers.tsx",
      "parent": "/$orgId/(dashboard)/dashboard"
    },
    "/$orgId/(dashboard)/dashboard/sshkeys": {
      "filePath": "$orgId/(dashboard)/dashboard.sshkeys.tsx",
      "parent": "/$orgId/(dashboard)/dashboard"
    },
    "/$orgId/(dashboard)/dashboard/storage": {
      "filePath": "$orgId/(dashboard)/dashboard.storage.tsx",
      "parent": "/$orgId/(dashboard)/dashboard"
    },
    "/$orgId/(dashboard)/dashboard/templates": {
      "filePath": "$orgId/(dashboard)/dashboard.templates.tsx",
      "parent": "/$orgId/(dashboard)/dashboard"
    },
    "/$orgId/(dashboard)/dashboard/users": {
      "filePath": "$orgId/(dashboard)/dashboard.users.tsx",
      "parent": "/$orgId/(dashboard)/dashboard"
    },
    "/$orgId/(dashboard)/dashboard/servers_/new": {
      "filePath": "$orgId/(dashboard)/dashboard.servers_.new.tsx",
      "parent": "/$orgId/(dashboard)/dashboard"
    },
    "/$orgId/(dashboard)/dashboard/templates_/new": {
      "filePath": "$orgId/(dashboard)/dashboard.templates_.new.tsx",
      "parent": "/$orgId/(dashboard)/dashboard"
    }
  }
}
ROUTE_MANIFEST_END */
