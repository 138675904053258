import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { APIFetch, MutProps, useTokenAndOrgId } from "../utils";
import { CreateTemplateData, ServerTemplateResponse } from "./types";

export const useListServerTemplates = () => {
  const { orgId, token } = useTokenAndOrgId();

  return useQuery({
    queryKey: ["list-server-templates", orgId, token],
    enabled: !!orgId && !!token,
    queryFn: async (): Promise<ServerTemplateResponse[]> => {
      const body = {
        org_id: orgId,
        page_number: 1,
        page_size: 10,
      };

      const result = await APIFetch.post("listServerTemplates", {
        token,
        body,
      });

      return result.data.templates;
    },
  });
};

export const useCreateServerTemplate = (props?: MutProps) => {
  const { token, orgId } = useTokenAndOrgId();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: CreateTemplateData) => {
      const result = await APIFetch.post("createServerTemplate", {
        token,
        body: {
          org_id: orgId,
          template_name: data.templateName,
          machine_image: data.machineImage,
          cpu_request_count: data.cpuRequestCount,
          ram_request_gb: data.ramRequestGb,
          gpu_request_count: data.gpuRequestCount,
          gpu_request_gmem: data.gpuRequestGmem,
        },
      });

      queryClient.invalidateQueries({
        queryKey: ["list-server-templates"],
        exact: false,
      });

      return result.status;
    },
    ...props,
  });
};

export const useDeleteServerTemplate = (props?: MutProps) => {
  const { token, orgId } = useTokenAndOrgId();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (templateId: number) => {
      const result = await APIFetch.post("deleteServerTemplate", {
        token,
        body: {
          org_id: orgId,
          template_id: templateId,
        },
      });

      queryClient.invalidateQueries({
        queryKey: ["list-server-templates"],
        exact: false,
      });

      return result.status;
    },
    ...props,
  });
};
