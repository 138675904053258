import { useListServerTemplates } from "@/services/template";
import TemplateCard from "./templateCard";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { ChevronDownIcon, Plus } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Spinner } from "@/components/spinner";

const DashboardTemplates = () => {
  const { data: templates, isLoading, error } = useListServerTemplates();

  if (isLoading) {
    return <Spinner />
  }

  if (error) {
    return <div>Error loading templates: {error.message}</div>;
  }

  return (
    <div className="mx-auto space-y-10">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-3xl font-medium">Server Templates</h2>
        <Button disabled>
          <Plus className="w-4 h-4 mr-2" />
          New Template
        </Button>
      </div>

      {templates && templates.length > 0 ? (
        <div className="space-y-4">
          {templates.map(
            (template) => (
              <TemplateCard
                key={template.template_id}
                index={String(template.template_id)}
                name={template.template_name}
                cpuRequestCount={Number(template.cpu_count)}
                ramRequestGb={Number(template.ram_gb)}
                gpuRequestCount={Number(template.gpu_request_count)}
                gpuRequestGmem={Number(template.gpu_request_gmem)}
                machineImage={template.machine_image}
                price={1.99}
                selected={false}
                onSelect={() => { }}
              />
            ),
          )}
        </div>
      ) : (
        <div className="text-center p-10 bg-foreground/10 rounded-lg">
          <p className="text-lg">There are no templates available.</p>
        </div>
      )}
    </div>
  );
};

export default DashboardTemplates;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const RightMenus = () => {
  return (
    <div className="flex space-x-4 ml-auto">
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <MenuButton className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
            GPU
            <ChevronDownIcon
              aria-hidden="true"
              className="-mr-1 h-5 w-5 text-gray-400"
            />
          </MenuButton>
        </div>
        <MenuItems
          transition
          className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none"
        >
          <div className="py-1">
            <MenuItem>
              <a
                href="#"
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              >
                GPU Option 1
              </a>
            </MenuItem>
            <MenuItem>
              <a
                href="#"
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              >
                GPU Option 2
              </a>
            </MenuItem>
          </div>
        </MenuItems>
      </Menu>

      <Menu as="div" className="relative inline-block text-left">
        <div>
          <MenuButton className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
            Status
            <ChevronDownIcon
              aria-hidden="true"
              className="-mr-1 h-5 w-5 text-gray-400"
            />
          </MenuButton>
        </div>
        <MenuItems
          transition
          className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none"
        >
          <div className="py-1">
            <MenuItem>
              <a
                href="#"
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              >
                Active
              </a>
            </MenuItem>
            <MenuItem>
              <a
                href="#"
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              >
                Inactive
              </a>
            </MenuItem>
          </div>
        </MenuItems>
      </Menu>

      <Menu as="div" className="relative inline-block text-left">
        <div>
          <MenuButton className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
            Balance: $94
            <ChevronDownIcon
              aria-hidden="true"
              className="-mr-1 h-5 w-5 text-gray-400"
            />
          </MenuButton>
        </div>
        <MenuItems
          transition
          className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none"
        >
          <div className="py-1">
            <MenuItem>
              <a
                href="#"
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              >
                Add Balance
              </a>
            </MenuItem>
            <MenuItem>
              <a
                href="#"
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              >
                View Balance
              </a>
            </MenuItem>
          </div>
        </MenuItems>
      </Menu>
    </div>
  );
};
