import React, { useState } from "react";
import { TrashIcon, CircleEllipsis } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import {
  useGetServerInstance,
  useDeleteServerInstance,
} from "@/services/server";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Separator } from "@/components/ui/separator";
import { Input } from "@/components/ui/input";
import { formatDate, useElapsedTime } from "@/helpers/time";
import { Spinner } from "@/components/spinner";
import { ServerInstance } from "@/services/server/types";
import { CopyButton } from "@/components/copy-button";
import { toast } from "react-toastify";

interface ServerCardProps {
  name: string;
  status: string;
  baseImage: string;
  createdBy: string;
  createdAt: string;
  instanceId: number;
}

export const ServerCard: React.FC<ServerCardProps> = ({
  name,
  status,
  baseImage,
  createdBy,
  createdAt,
  instanceId,
}) => {
  const deleteServerMutation = useDeleteServerInstance();

  const handleDelete = () => {
    deleteServerMutation.mutate(instanceId);
  };

  const createdDate = formatDate(createdAt);
  const uptime = useElapsedTime(createdAt);

  return (
    <div
      className={`flex flex-col gap-1 p-6 rounded-sm shadow-md mb-4 border-2 ${status === "Running" ? "border-primary" : "border-border"}`}
    >
      <div className="flex w-full items-center justify-between">
        <h3 className="text-xl font-semibold">{name}</h3>
      </div>

      <p className="mt-2 text-foreground/60 text-sm">Template: {baseImage}</p>

      <p className="text-sm mb-2">
        Created by <span className="text-primary font-medium">{createdBy}</span>{" "}
        on {createdDate}.
      </p>

      <div className="flex w-full items-center justify-between gap-4">
        <div className="flex items-center gap-4">
          <Badge size="lg" variant="primary">
            {status} • {uptime}
          </Badge>
        </div>

        <div className="flex items-center gap-3">
          <SeeMoreDialog instanceId={instanceId} />
          <DeleteServerDialog onDelete={handleDelete} serverName={name} />
        </div>
      </div>
    </div>
  );
};

const SeeMoreDialog = ({ instanceId }: { instanceId: number }) => {
  const [open, setOpen] = useState(false);
  const { data: serverDetails, isLoading } = useGetServerInstance(
    instanceId,
    open
  );

  const handleDialogOpen = (isOpen: boolean) => {
    setOpen(isOpen);
  };

  return (
    <Dialog open={open} onOpenChange={handleDialogOpen}>
      <DialogTrigger asChild>
        <Button className="flex gap-2">
          <CircleEllipsis className="w-4 h-4" /> Server Details
        </Button>
      </DialogTrigger>
      <DialogContent className="max-w-3xl">
        <DialogHeader>
          <DialogTitle></DialogTitle>
        </DialogHeader>
        {isLoading ? (
          <Spinner />
        ) : serverDetails ? (
          <DialogContentWrapper serverDetails={serverDetails} />
        ) : (
          <p>No details available</p>
        )}
      </DialogContent>
    </Dialog>
  );
};

const DialogContentWrapper = ({
  serverDetails,
}: {
  serverDetails: ServerInstance;
}) => {
  const {
    instance_name,
    status,
    template_name,
    image,
    email,
    created_at,
    storage_volume_name,
    storage_volume_id,
    metadata,
    service_urls,
    started_at,
  } = serverDetails;

  const uptime = useElapsedTime(started_at);
  const { ssh, extra } = metadata;
  const sshCommand = ssh
    ? `ssh ${ssh.username}@${ssh.ip} -p ${ssh.port}`
    : "SSH information not available";

  const createdDate = formatDate(created_at);

  return (
    <>
      <DialogHeader>
        <DialogTitle className="flex items-center gap-4">
          {instance_name}
          <Badge size="lg" variant="primary">
            {status} • {uptime}
          </Badge>
        </DialogTitle>
      </DialogHeader>

      <div className="w-full flex flex-col gap-3">
        <p className="text-lg mb-2 font-semibold">Template: {template_name}</p>
        <p className="text-sm text-gray-600 mb-2">Base Image: {image}</p>
        <p className="text-sm mb-2">
          Created by <span className="text-primary font-medium">{email}</span>{" "}
          on {createdDate}.
        </p>
      </div>

      <div className="pb-6">
        <Separator className="my-4" />
        <div className="space-y-4">
          <div>
            <h3 className="font-semibold mb-2">SSH Access</h3>
            {ssh ? (
              <div className="flex items-center gap-3">
                <p className="flex-grow font-mono bg-primary/10 rounded-sm px-4 py-2 truncate">
                  {sshCommand}{" "}
                  <span className="text-primary/50">{"-i <ssh_key_path>"}</span>
                </p>
                <CopyButton textToCopy={sshCommand} variant="default" />
              </div>
            ) : (
              <p>Not enabled</p>
            )}
          </div>

          <div>
            <h3 className="font-semibold mb-2">Storage Mount</h3>
            {!storage_volume_id ? (
              <p>No Storage Mounted</p>
            ) : (
              <p>
                Volume "{storage_volume_name}" attached.{" "}
                <span className="text-gray-500">
                  Volume ID: {storage_volume_id}
                </span>
              </p>
            )}
          </div>

          <div>
            <h3 className="font-semibold mb-2">HTTP Ports</h3>
            {service_urls?.map((service) => (
              <div
                key={service.mapped_port}
                className="flex items-center space-x-2 mb-2"
              >
                <Badge size="lg" variant="primary">
                  :{service.mapped_port}
                </Badge>
                <span>{service.name}</span>
                <a
                  href={service.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-primary hover:underline"
                >
                  {service.url}
                </a>
              </div>
            ))}
          </div>

          <div>
            <h3 className="font-semibold mb-2">Extra</h3>
            {extra &&
              Object.entries(extra).map(([key, value]) => (
                <p key={key} className="text-sm">
                  <span className="font-medium">{key}:</span> <b>{value}</b>
                </p>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

const DeleteServerDialog = ({
  serverName,
  onDelete,
}: {
  serverName: string;
  onDelete: () => void;
}) => {
  const [inputValue, setInputValue] = useState("");
  const isNameMatched = inputValue.trim() === serverName.trim();
  const isDisabled = !isNameMatched;

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button className="flex gap-2 items-center" variant="outline">
          <TrashIcon className="w-4 h-4" /> Delete
        </Button>
      </DialogTrigger>
      <DialogContent className="max-w-3xl p-10">
        <DialogHeader className="gap-3">
          <DialogTitle className="flex items-center gap-4 font-semibold">
            Are you sure you want to delete this server?
          </DialogTitle>

          <p className="text-destructive">
            This action is destructive and cannot be undone. All running tasks
            will terminate immediately.
          </p>
        </DialogHeader>

        {/* Display server name */}
        <div className="w-[70%] p-4 rounded-sm border border-border flex flex-col gap-1 my-4">
          <p className="text-foreground/80 font-semibold">Server Name: {serverName}</p>
        </div>

        <Separator />

        <form className="space-y-4" onSubmit={(e) => {
          e.preventDefault();
          if (isNameMatched) {
            onDelete();
          } else {
            toast.error("Server name did not match");
          }
        }}>
          <div className="flex flex-col gap-5">
            <p className="text-destructive">
              Please type the server name below to confirm this action:
            </p>

            <Input
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              required
              placeholder="Type the server name to confirm"
              className="w-full"
            />
          </div>

          <DialogFooter>
            <DialogClose asChild>
              <Button variant="outline">Cancel</Button>
            </DialogClose>
            <Button
              type="submit"
              disabled={isDisabled}
              className={isDisabled ? "opacity-50 cursor-not-allowed" : ""}
            >
              Delete
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};
