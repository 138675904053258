import React from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb"
import { Link, useRouterState } from "@tanstack/react-router";

export const Header = () => {
  return (
    <header className="flex items-center justify-between px-6">
      <LinkBreadCrumbs />
    </header>
  );
};

const LinkBreadCrumbs = () => {
  const breadcrumbs = useRouterState({
    select: state => {
      return state.matches
        .map(match => ({
          title: match.meta?.find(tag => tag.title)!.title as string,
          path: match.pathname,
        }))
        .filter(crumb => Boolean(crumb.title))
    },
  })

  return (
    <Breadcrumb>
      <BreadcrumbList>
        {breadcrumbs.map((crumb, i) => {
          if (!crumb) return null

          return (
            <React.Fragment key={i}>
              <BreadcrumbItem>
                <BreadcrumbLink asChild><Link to={crumb.path}>{crumb.title}</Link></BreadcrumbLink>
              </BreadcrumbItem >
              {i === breadcrumbs.length - 1 ? null : <BreadcrumbSeparator />}
            </React.Fragment>
          )
        })}
      </BreadcrumbList>
    </Breadcrumb >
  )
}
