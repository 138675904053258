const ConsoleMasthead = () => {
  const styles = {
    title: ["color: #ed1e79", "font-size: 10px", "font-weight: bold"].join(";"),

    subtitle: ["color: #ed1e79", "font-size: 14px", "font-weight: normal"].join(
      ";",
    ),

    info: ["color: #ed1e79", "font-size: 12px"].join(";"),
  };

  // @ts-expect-error - This variable is injected by Vite at build time
  const commitSha = __COMMIT_SHA__;
  // @ts-expect-error - This variable is injected by Vite at build time
  const gitBranch = __GIT_BRANCH__;

  console.log(
    `%c
    _                     
    . _ (_ _ _ _ _  _ _    _ . 
    || )| (-| (-| )(_(-.  (_|| 
                               
    `,
    styles.title,
  );

  console.log(`%c• Commit: ${commitSha}\n• Branch: ${gitBranch}`, styles.info);

  return null; // This component doesn't render anything to the DOM
};

export default ConsoleMasthead;
