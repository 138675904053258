import { useMutation, useQueryClient } from "@tanstack/react-query";
import { API_URL, APIFetch, MutProps, useTokenAndOrgId } from "../utils";

export const useUpdateUserProfile = (props?: MutProps) => {
  const { token, orgId } = useTokenAndOrgId();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: { firstName: string; lastName: string }) => {
      const result = await APIFetch.post("updateOrgUserProfile", {
        token,
        body: {
          org_id: orgId,
          first_name: data.firstName,
          last_name: data.lastName,
        },
      });

      // invalidate the query to refetch the data
      queryClient.invalidateQueries({
        queryKey: ["get-org-user-profile"],
        exact: false,
      });

      return result.status;
    },
    ...props,
  });
};

export const useUpdateOrgProfile = (props?: MutProps) => {
  const { token, orgId } = useTokenAndOrgId();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (formData: FormData) => {
      formData.append("org_id", String(orgId));

      // Debug: Log all entries in FormData
      for (const pair of formData.entries()) {
        console.log(pair[0] + ": " + pair[1]);
      }

      const result = await fetch(`${API_URL}/updateOrgProfile`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      // invalidate the query to refetch the data
      queryClient.invalidateQueries({
        queryKey: ["get-org-user-profile"],
        exact: false,
      });

      return result.status;
    },
    ...props,
  });
};
