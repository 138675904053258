import { useActiveOrgId } from "@/hooks/useActiveOrgId";
import { useUserStore } from "@/store/UserStore";

export const API_URL = import.meta.env.VITE_API_URL as string;

type FetchOptions = {
  token: string;
};

export class APIFetch {
  // authenticated requests
  static async get(endpoint: string, op: FetchOptions) {
    const resRaw = await fetch(`${API_URL}/${endpoint}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${op.token}`,
      },
    });

    const res = await resRaw.json();

    if (!resRaw.ok) {
      throw new Error(res.error);
    }

    return res;
  }

  static async post(endpoint: string, op: FetchOptions & { body?: object }) {
    const resRaw = await fetch(`${API_URL}/${endpoint}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${op.token}`,
      },
      body: JSON.stringify(op.body),
    });

    const res = await resRaw.json();

    if (!resRaw.ok) {
      throw new Error(res.error);
    }

    return res;
  }

  static async post_public(endpoint: string, op: { body?: object }) {
    const resRaw = await fetch(`${API_URL}/${endpoint}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(op.body),
    });

    const res = await resRaw.json();

    if (!resRaw.ok) {
      throw new Error(res.error);
    }

    return res;
  }
}

// utility function to get the token & orgId
export const useTokenAndOrgId = () => {
  const { orgId } = useActiveOrgId();
  const token = useUserStore((state) => state.user?.token) || "";

  return { orgId, token };
}

export type MutProps = {
  onSuccess?: (data: unknown) => void;
  onError?: (error: Error) => void;
};
