import { Link, useLocation } from "@tanstack/react-router";
import {
  Archive,
  Key,
  LayoutGrid,
  Unplug,
  User,
  Users,
} from "lucide-react";
import { Button } from "@/components/ui/button";

import { cn } from "@/lib/utils";
import { SidebarContent, SidebarGroup, SidebarGroupContent, SidebarGroupLabel, SidebarMenu, SidebarMenuButton, SidebarMenuItem } from "@/components/ui/sidebar";
import { useActiveOrgProfile } from "@/hooks/useActiveOrgProfile";
import { isRoleAdmin } from "@/services/auth/utils";
import { useActiveOrgId } from "@/hooks/useActiveOrgId";


export const DashboardSidebarContent = () => {
  const { orgId: activeOrgId } = useActiveOrgId();

  const { data } = useActiveOrgProfile()
  const isAdmin = isRoleAdmin(data?.role)

  const location = useLocation();
  const matchLocation = (path: string) => {
    return location.pathname.trim().includes(path);
  };

  return (
    <SidebarContent className="px-2">
      <SidebarGroup>
        <SidebarGroupLabel>Manage</SidebarGroupLabel>
        <SidebarGroupContent>
          <SidebarMenu>
            {[
              {
                name: "Servers",
                icon: Unplug,
                link: "servers",
              },
              {
                name: "Storage",
                icon: Archive,
                link: "storage",
              },
              {
                name: "SSH Key",
                icon: Key,
                link: "sshkeys",
              },
            ].map((item) => {
              return (
                <SidebarMenuItem key={item.link}>
                  <SidebarMenuButton asChild>
                    <Button
                      asChild
                      variant="ghost"
                      className={cn(
                        "justify-start",
                        matchLocation(item.link)
                          ? "bg-background font-medium text-primary hover:bg-background hover:text-primary"
                          : "hover:bg-background/50"
                      )}
                    >
                      <Link
                        to={"/$orgId/dashboard/" + item.link}
                        params={{ orgId: activeOrgId?.toString() }}
                      >
                        {item.icon && <item.icon className="h-4 w-4 mr-2" />}
                        {item.name}
                      </Link>
                    </Button>
                  </SidebarMenuButton>
                </SidebarMenuItem>
              );
            })}
          </SidebarMenu>
        </SidebarGroupContent>
      </SidebarGroup>


      <SidebarGroup>
        <SidebarGroupLabel>My Account</SidebarGroupLabel>
        <SidebarGroupContent>
          <SidebarMenu>
            {[
              {
                name: "Profile",
                icon: User,
                link: "profile",
              },
              isAdmin ? {
                name: "User Management",
                icon: Users,
                link: "users",
              } : null,
            ].map((item) => {
              if (!item) return

              return (
                <SidebarMenuItem key={item.link}>
                  <SidebarMenuButton asChild>
                    <Button
                      asChild
                      variant="ghost"
                      className={cn(
                        "justify-start",
                        matchLocation(item.link)
                          ? "bg-background font-medium text-primary hover:bg-background hover:text-primary"
                          : "hover:bg-background/50"
                      )}
                    >
                      <Link
                        to={"/$orgId/dashboard/" + item.link}
                        params={{ orgId: activeOrgId?.toString() }}
                      >
                        {item.icon && <item.icon className="h-4 w-4 mr-2" />}
                        {item.name}
                      </Link>
                    </Button>
                  </SidebarMenuButton>
                </SidebarMenuItem>
              );
            })}
          </SidebarMenu>
        </SidebarGroupContent>
      </SidebarGroup>

    </SidebarContent>
  )
}


