/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { APIFetch, MutProps, useTokenAndOrgId } from "../utils";
import { useMutation, useQuery, useQueryClient, useInfiniteQuery } from "@tanstack/react-query";
import { getUserSort  } from "@/pages/DashboardUserManagement/types";
import { UsersSortKeys } from "@/pages/DashboardUserManagement/types";
export * from "./types";

const LIST_USERS_PAGE_SIZE = 50;

export const getOrgUsers = async (
  token: string,
  orgId: string,
  pageSize: number = LIST_USERS_PAGE_SIZE,
  pageNumber: number = 1,
  searchText: string = "",
  sort: UsersSortKeys = UsersSortKeys.CREATED_AT_DESC 
) => {
  const result = await APIFetch.post("getOrgUsers", {
    token,
    body: {
      org_id: Number(orgId),
      page_size: pageSize,
      page_number: pageNumber,
      search_text: searchText, 
      sort_by: getUserSort(sort).sortBy, 
      sort_direction: getUserSort(sort).sortDirection, 
    },
  });

  if (result.error) {
    console.error(`Error from API: ${result.error}`);
    throw new Error(`Error from API: ${result.error}`);
  }

  const usersData = result.data?.users;

  if (!usersData) {
    throw new Error("No users found in the organization");
  }

  return usersData.map((user: any) => ({
    user_id: user.user_id,
    email: user.user.email,
    role_name: user.role.role_name,
    joinedDate: user.created_at,
    status: user.status,
    is_super: user.user.is_super,
    profile_metadata: {
      first_name: user.profile_metadata?.first_name || "",
      last_name: user.profile_metadata?.last_name || "",
    },
  }));
};

export const useGetOrgUsers = (searchText = "", sort: UsersSortKeys = UsersSortKeys.CREATED_AT_DESC) => {
  const { orgId, token } = useTokenAndOrgId();

  const fetchOrgUsers = async ({ pageParam = 1 }) => {
    return await getOrgUsers(token, orgId.toString(), LIST_USERS_PAGE_SIZE, pageParam, searchText, sort);
  };

  return useInfiniteQuery({
    queryKey: ["org-users", orgId, searchText, sort],
    queryFn: fetchOrgUsers,
    enabled: !!orgId && !!token,
    getNextPageParam: (lastPage, pages) => {
      return lastPage.length === LIST_USERS_PAGE_SIZE ? pages.length + 1 : undefined;
    },
    initialPageParam: 1,
  });
};

export const useCreateOrgInvite = (props?: MutProps) => {
  const { orgId, token } = useTokenAndOrgId();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: { email: string; role: string }) => {
      const result = await APIFetch.post("createInvite", {
        token,
        body: { org_id: orgId, role: data.role },
      });

      queryClient.invalidateQueries({
        queryKey: ["org-users", orgId, data.email],
        exact: false,
      });

      return result;
    },
    ...props,
  });
};

export const useCreateOrgInviteBatch = (props?: MutProps) => {
  const { orgId, token } = useTokenAndOrgId();
  const queryClient = useQueryClient();

  return useMutation({
    // TODO: property add TS type to 'role' field
    mutationFn: async (data: { email: string; role: string }[]) => {
      const result = await APIFetch.post("createInviteBatch", {
        token,
        body: { org_id: orgId, invites: data },
      });

      queryClient.invalidateQueries({
        queryKey: ["org-users", orgId],
        exact: false,
      });

      return result;
    },
    ...props,
  });
};

export const useRemoveOrgUser = (props?: MutProps) => {
  const { orgId, token } = useTokenAndOrgId();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (userId: number) => {
      console.log("userId:", userId);

      const result = await APIFetch.post("deleteOrgUser", {
        token,
        body: { org_id: orgId, user_id: userId },
      });

      queryClient.invalidateQueries({
        queryKey: ["org-users"],
        exact: false,
      });

      return result;
    },
    ...props,
  });
};

export const useUpdateOrgUserRole = (props?: MutProps) => {
  const { orgId, token } = useTokenAndOrgId();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: { userId: number; roleId: number }) => {
      const result = await APIFetch.post("updateOrgUser", {
        token,
        body: { org_id: orgId, role_id: data.roleId, user_id: data.userId },
      });

      queryClient.invalidateQueries({
        queryKey: ["org-users", orgId, data.userId],
        exact: false,
      });

      return result;
    },
    ...props,
  });
};
