import { Badge } from "@/components/ui/badge";
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";
import { Microchip, Fan, Disc3 } from "lucide-react";

interface TemplateCardProps {
  index: string;
  name: string;
  cpuRequestCount: number;
  ramRequestGb: number;
  gpuRequestCount: number;
  gpuRequestGmem: number;
  machineImage: string;
  price: number;
  selected: boolean;
  onSelect: () => void;
}

const TemplateCard = ({
  index,
  name,
  cpuRequestCount,
  ramRequestGb,
  gpuRequestCount,
  gpuRequestGmem,
  machineImage,
  price,
  selected,
  onSelect
}: TemplateCardProps) => {
  return (
    <Card
      key={index}
      className={`cursor-pointer transition-all hover:shadow-md ${
        selected ? "ring-2 ring-primary" : ""
      }`}
      onClick={onSelect}
    >
      <CardHeader className="pb-2">
        <CardTitle className="flex justify-between items-center">
          <span className="text-lg font-bold pb-3">{name}</span>
          <Badge variant="outline" className="text-primary">
            ${price}/hr
          </Badge>
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          <div className="flex items-center space-x-2">
            <Microchip className="h-5 w-5 text-muted-foreground" />
            <span className="text-sm">
              {cpuRequestCount}x vCPU, {ramRequestGb}GB RAM
            </span>
          </div>
          <div className="flex items-center space-x-2">
            <Fan className="h-5 w-5 text-muted-foreground" />
            <span className="text-sm">
            {gpuRequestCount}x GPU, {gpuRequestGmem}GB VRAM
            </span>
          </div>
          <div className="flex items-center space-x-2">
            <Disc3 className="h-5 w-5 text-muted-foreground" />
            <span className="text-sm text-muted-foreground">
              Machine Image: {machineImage}
            </span>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default TemplateCard;
