import { useMemo, useState } from "react";
import { useUserStore } from "@/store/UserStore";
import { StorageVolume } from "@/types";
import { ManageStorageHeader } from "./header";
import { StorageCard } from "./storageCard";
import { useListStorageVolumes } from "@/services/storage";
import { StorageViewSortKeys, StorageViewType } from "./types";
import { Spinner } from "@/components/spinner";
import { Virtuoso } from 'react-virtuoso'

export const DashboardStorage = () => {
  const { user } = useUserStore();
  const currentUserEmail = user?.email;

  const [storageView, onStorageViewChange] = useState(StorageViewType.MY_STORAGE);
  const [storageSort, onStorageSortChange] = useState(
    StorageViewSortKeys.CREATED_AT_DESC
  );
  const [searchTerm, setSearchTerm] = useState("");

  const {
    data,
    isLoading,
    isError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useListStorageVolumes({
    sort: storageSort,
    searchTerm,
    showAllUsers: storageView !== StorageViewType.MY_STORAGE
  });

  const allVolumes = data?.pages.flatMap((page) => page.volumes) || [];
  const myVolumesList = useMemo(() => {
    return allVolumes?.filter((volume: StorageVolume) => volume.user_email === currentUserEmail);
  }, [allVolumes, currentUserEmail]);

  const currentVolumes =
    storageView === StorageViewType.MY_STORAGE ? myVolumesList : allVolumes;

  return (
    <div>
      <ManageStorageHeader
        storageViewType={storageView}
        onStorageViewChange={onStorageViewChange}

        storageViewSortType={storageSort}
        onStorageViewSortChange={onStorageSortChange}
        onSearchChange={setSearchTerm}

      />

      {isLoading ? (
        <Spinner />
      ) : isError ? (
        <div className="mx-auto text-center text-red-500">
          Failed to load storage volumes.
        </div>
      ) : currentVolumes.length === 0 ? (
        <div className="mx-auto flex flex-col items-center gap-16 mt-28">
          {searchTerm.length > 0 ? (
            <h3 className="text-4xl font-medium">
              No search results.
            </h3>
          ) : (
            <h3 className="text-4xl font-medium">You have no storage volumes.</h3>
          )}

          <img
            src="/assets/image/gpu-pink.png"
            className="w-full max-w-lg"
            alt="No storage volumes"
          />
        </div>
      ) : (
        <Virtuoso
          useWindowScroll
          data={currentVolumes}
          endReached={() => {
            if (hasNextPage) {
              fetchNextPage();
            }
          }}
          itemContent={(index, volume) => {
            return (
              <div key={index} className="my-4">
                <StorageCard {...volume} />
              </div>
            )
          }}
          components={{
            Footer: () => {
              return (
                <div className="text-center py-8">
                  {isFetchingNextPage ? <Spinner /> : hasNextPage ? null : <p className="text-sm text-foreground/50">End of list.</p>}
                </div>
              )
            }
          }}
        />
      )}
    </div>
  );
};
