import { ComponentProps, useEffect, useState } from "react";
import { Button } from "./ui/button";
import { CheckIcon } from "lucide-react";
import { cn } from "@/lib/utils";

type CopyButtonProps = ComponentProps<typeof Button> & {
  label?: string;
  textToCopy: string;
};

export const CopyButton = ({
  className,
  label = "Copy",
  textToCopy,
  onClick,
  ...otherProps
}: CopyButtonProps) => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      const timeout = setTimeout(() => {
        setCopied(false);
      }, 2000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [copied]);

  return (
    <Button
      className={cn("flex items-center gap-2", className)}
      onClick={(e) => {
        navigator.clipboard.writeText(textToCopy);
        setCopied(true);

        if (onClick) {
          onClick(e);
        }
      }}
      {...otherProps}
    >
      {copied ? (
        <>
          {" "}
          <CheckIcon className="w-4 h-4" /> Copied!
        </>
      ) : (
        label
      )}
    </Button>
  );
};
