import { Sort } from "@/constants/sort";

export enum StorageViewType {
  MY_STORAGE = "my-storage",
  USERS_STORAGE = "users-storage",
}

export enum StorageViewSortKeys {
  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
  STORAGE_NAME_ASC = "STORAGE_NAME_ASC",
  STORAGE_NAME_DESC = "STORAGE_NAME_DESC",
}

// Create the sort configurations
export const StorageViewSortBy = {
  [StorageViewSortKeys.CREATED_AT_ASC]: {
    sortBy: "created_at",
    sortDirection: "asc" as const,
  },
  [StorageViewSortKeys.CREATED_AT_DESC]: {
    sortBy: "created_at",
    sortDirection: "desc" as const,
  },
  [StorageViewSortKeys.STORAGE_NAME_ASC]: {
    sortBy: "volume_name",
    sortDirection: "asc" as const,
  },
  [StorageViewSortKeys.STORAGE_NAME_DESC]: {
    sortBy: "volume_name",
    sortDirection: "desc" as const,
  },
} as const;

// Derive the type from the const object
export type StorageViewSortBy =
  (typeof StorageViewSortBy)[keyof typeof StorageViewSortBy];

// Helper function to ensure type safety when accessing sort values
export function getStorageViewSort(key: StorageViewSortKeys): Sort {
  return StorageViewSortBy[key];
}
