import React, { useState } from "react";
import { ManageServerHeader } from "./header";
import { ServerCard } from "./serverCard";
import { useListServerInstances } from "@/services/server";
import { Skeleton } from "@/components/ui/skeleton";
import { ServerViewSortKeys, ServerViewType } from "./types";
import { Button } from "@/components/ui/button";
import { Link } from "@tanstack/react-router";
import { Plus } from "lucide-react";
import { useActiveOrgId } from "@/hooks/useActiveOrgId";
import { Virtuoso } from 'react-virtuoso'
import { Spinner } from "@/components/spinner";
export const DashboardServers: React.FC = () => {
  const { orgId: activeOrgId } = useActiveOrgId();

  const [serverView, onServerViewChange] = useState(ServerViewType.MY_SERVER);
  const [serverSort, onServerSortChange] = useState(
    ServerViewSortKeys.STARTED_AT_DESC
  );
  const [searchTerm, setSearchTerm] = useState("");

  const {
    data,
    isLoading,
    isError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useListServerInstances({
    showAllUsers: serverView !== ServerViewType.MY_SERVER,
    sort: serverSort,
    searchText: searchTerm,
    refetchInterval: 10000,
    staleTime: 5000,
  });

  const allServers = data?.pages.flatMap((page) => page?.instances || []) || [];

  return (
    <div className="">
      <ManageServerHeader
        serverViewType={serverView}
        onServerViewChange={onServerViewChange}
        serverViewSortType={serverSort}
        onServerViewSortChange={onServerSortChange}
        onSearchChange={setSearchTerm}
      />

      {isLoading ? (
        <div className="mx-auto flex flex-col items-center gap-16 mt-28">
          <Skeleton className="w-full max-w-lg h-10" />
          <Skeleton className="w-full max-w-lg h-40" />
        </div>
      ) : isError ? (
        <div className="mx-auto text-center text-red-500">
          Failed to load servers.
        </div>
      ) : allServers.length === 0 ? (
        <div className="mx-auto flex flex-col items-center gap-16 mt-28">
          {searchTerm.length > 0 ? (
            <h3 className="text-4xl font-medium">
              No search results.
            </h3>
          ) : (
            <h3 className="text-4xl font-medium">
              You have no servers running.
            </h3>
          )}

          <img
            src="/assets/image/gpu-pink.png"
            className="w-full max-w-lg"
            alt="No servers running"
          />
          <Button asChild size="lg" className="w-fit gap-1">
            <Link to="/$orgId/dashboard/servers/new" params={{ orgId: activeOrgId.toString() }}>
              <Plus className="w-5 h-5" /> Create New Server
            </Link>
          </Button>
        </div>
      ) : (
        <div className="h-full">
          <Virtuoso
            useWindowScroll
            data={allServers}
            endReached={() => {
              if (hasNextPage) {
                fetchNextPage();
              }
            }}
            itemContent={(index, server) => (
              <div key={index} className="my-4">
                <ServerCard
                  key={server.instance_id}
                  name={server.instance_name}
                  status={server.status}
                  baseImage={server.template_name}
                  createdBy={server.email}
                  createdAt={server.created_at}
                  instanceId={server.instance_id}
                />
              </div>
            )}
            components={{
              Footer: () => (
                <div className="text-center py-8">
                  {isFetchingNextPage ? <Spinner /> : hasNextPage ? null : <p className="text-sm text-foreground/50">End of list.</p>}
                </div>
              ),
            }}
          />
        </div>
      )}
    </div>
  );
};


