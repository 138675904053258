import { useState } from "react"
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "./ui/dialog"
import { Button } from "./ui/button"

interface ConfirmNavigationProps {
    onConfirm: () => void
    trigger: React.ReactNode
  }
  
  export default function ConfirmNavigation({ onConfirm, trigger }: ConfirmNavigationProps) {
    const [isOpen, setIsOpen] = useState(false)
  
    const handleNavigate = () => {
      setIsOpen(false)
      onConfirm()
    }
  
    return (
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogTrigger asChild>
          {trigger}
        </DialogTrigger>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>Confirm Navigation</DialogTitle>
            <DialogDescription>
              You have unsaved changes. Are you sure you want to navigate away from this page?
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button variant="outline" onClick={() => setIsOpen(false)}>
              Cancel
            </Button>
            <Button onClick={handleNavigate}>
              Confirm
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    )
  }