import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from "@/components/ui/select";
import { Search } from "lucide-react";
import { useState, useEffect, useCallback } from "react";
import { InviteUsers } from "./invite-user";
import { UsersViewType, UsersSortKeys } from "./types";
import { useDebounce } from "@/hooks/useDebounce";

type ManageUsersHeaderProps = {
  usersViewType: UsersViewType;
  onUsersViewChange: (type: UsersViewType) => void;
  onSearchChange: (searchTerm: string) => void;
  usersViewSortType: UsersSortKeys;
  onUsersViewSortChange: (type: UsersSortKeys) => void;
};

export const UserManagementHeader = ({
  usersViewType,
  onUsersViewChange,
  onSearchChange,
  usersViewSortType,
  onUsersViewSortChange,
}: ManageUsersHeaderProps) => {
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm);

  useEffect(() => {
    onSearchChange(debouncedSearchTerm);
  }, [debouncedSearchTerm, onSearchChange]);

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(e.target.value);
    },
    []
  );

  return (
    <div className="w-full mx-auto">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-medium">User Management</h1>

        <InviteUsers /> 
      </div>

      <div className="flex items-center space-x-4 mb-6">
        <Tabs
          defaultValue={UsersViewType.EXISTING}
          className="w-[400px]"
          value={usersViewType}
          onValueChange={(val) => onUsersViewChange(val as unknown as UsersViewType)}
        >
          <TabsList className="grid w-full grid-cols-2">
            <TabsTrigger value={UsersViewType.EXISTING}>Existing Users</TabsTrigger>
            <TabsTrigger value={UsersViewType.PENDING}>Pending</TabsTrigger>
          </TabsList>
        </Tabs>

        <div className="relative flex-grow">
          <Search className="absolute w-4 h-4 left-3 top-1/2 transform -translate-y-1/2 text-foreground/40" />
          <Input
            type="text"
            placeholder="Search user by name or email..."
            className="pl-10 w-full"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </div>

        <Select
          value={usersViewSortType}
          onValueChange={onUsersViewSortChange}
        >
          <SelectTrigger className="w-[180px] border-0 shadow-none">
            <SelectValue placeholder="Sort by" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={UsersSortKeys.CREATED_AT_DESC}>Newest First</SelectItem>
            <SelectItem value={UsersSortKeys.CREATED_AT_ASC}>Oldest First</SelectItem>
            <SelectItem value={UsersSortKeys.FIRST_NAME_ASC}>First Name (A-Z)</SelectItem>
            <SelectItem value={UsersSortKeys.FIRST_NAME_DESC}>First Name (Z-A)</SelectItem>
            </SelectContent>
        </Select>
      </div>
    </div>
  );
};
