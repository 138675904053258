import { STATUS_SUCCESS } from "@/constants/status";
import { APIFetch, MutProps, useTokenAndOrgId } from "../utils";
import { useMutation, useQuery } from "@tanstack/react-query";
import { UserOrgsReturn } from "./types";

// Function to get the list of organizations that the user belongs to
export const getUserOrgs = async (token: string): Promise<UserOrgsReturn> => {
  const result = await APIFetch.get("getUserOrgs", { token });

  if (result.error) {
    console.error(`Error from API: ${result.error}`);
    throw new Error(`Error from API: ${result.error} `);
  }

  const orgsData = result.data?.orgs;

  if (orgsData) {
    const adaptedData: UserOrgsReturn = {
      organizations: orgsData.map((orgItem: any) => {
        const { role, ...otherData } = orgItem;

        return {
          orgId: orgItem.org.org_id,
          name: orgItem.org.name,
          role: orgItem.role.role_name,
          role_data: role,
          metadata: orgItem.org.metadata,
          profile_metadata: orgItem.org.profile_metadata,
          user_profile: {
            first_name: orgItem.profile.first_name,
            last_name: orgItem.profile.last_name,
          },
          ...otherData
        }
      }),
    };

    return adaptedData;
  } else {
    throw new Error("No organizations found for the user");
  }
};

export const useGetUserOrgs = () => {
  const { token } = useTokenAndOrgId();

  return useQuery({
    queryKey: ["get-user-orgs", token],
    enabled: !!token,
    queryFn: async () => getUserOrgs(token),
  });
};

export const ORG_NOT_FOUND_ERROR_MSG = "Organization not found";
export const useGetOrgUserProfile = (orgId: number) => {
  const { token } = useTokenAndOrgId();

  return useQuery({
    queryKey: ["get-org-user-profile", orgId, token],
    enabled: !!token && !!orgId && orgId > 0,
    queryFn: async () => {
      const userOrgs = await getUserOrgs(token);
      const org = userOrgs.organizations.find((org) => org.orgId === orgId);

      if (!org) {
        throw new Error(ORG_NOT_FOUND_ERROR_MSG);
      }

      return org;
    },
  });
}


// MUTATIONS
export const useRequestOTP = (props: MutProps) => {
  return useMutation({
    mutationFn: async (email: string) => {
      const result = await APIFetch.post_public("request-otp", {
        body: { email },
      });

      if (result.error) {
        throw new Error(`Error: ${result.error}`);
      }

      if (result.status !== STATUS_SUCCESS) {
        throw new Error("Failed to request OTP, please try again.");
      }

      return { success: true };
    },
    ...props
  })
}

export const useLogin = (props: MutProps) => {
  return useMutation({
    mutationFn: async (body: { email: string, otp: string, inviteCode?: string }) => {
      const { email, otp, inviteCode } = body;

      const result = await APIFetch.post_public("login", {
        body: { email, otp, invite_code: inviteCode },
      });

      if (result.error) {
        throw new Error(`Error: ${result.error}`);
      }

      if (result.status !== STATUS_SUCCESS) {
        throw new Error(
          "Login failed, please check your credentials and try again.",
        );
      }

      const data = result.data;

      return data; // Ensure that the response contains a token and role
    },
    ...props
  })
}


