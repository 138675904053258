import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from "@/components/ui/select";
import { Search } from "lucide-react";

import { StorageViewType } from "./types";
import { CreateStorageVolume } from "./createStorageVolume";
import { cn } from "@/lib/utils";
import { useCallback, useEffect, useState } from "react";
import { useActiveOrgProfile } from "@/hooks/useActiveOrgProfile";
import { isRoleUser } from "@/services/auth/utils";
import { useDebounce } from "@/hooks/useDebounce";

import { StorageViewSortKeys } from "./types";

type ManageStorageHeaderProps = {
  storageViewType: StorageViewType;
  onStorageViewChange: (type: StorageViewType) => void;

  storageViewSortType: StorageViewSortKeys;
  onStorageViewSortChange: (type: StorageViewSortKeys) => void;
  onSearchChange: (searchTerm: string) => void;
};

export const ManageStorageHeader = ({
  storageViewType,
  onStorageViewChange,

  storageViewSortType,
  onStorageViewSortChange,
  onSearchChange

}: ManageStorageHeaderProps) => {
  const { data } = useActiveOrgProfile();
  const role = data?.role;

  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm);

  useEffect(() => {
    onSearchChange(debouncedSearchTerm);
  }, [debouncedSearchTerm, onSearchChange]);

  const handleSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  }, []);



  useEffect(() => {
    // making sure the tabs is set to 'My Storage' if role is user
    if (isRoleUser(role) && storageViewType !== StorageViewType.MY_STORAGE) {
      onStorageViewChange(StorageViewType.MY_STORAGE);
    }
  }, [role]);

  return (
    <div className="w-full mx-auto">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-medium">Storage Volumes</h1>

        <CreateStorageVolume />
      </div>

      <p className="mb-6 text-foreground/80 hidden">
        Total Storage Allocated: 1.5TB, 0.8TB currently used.
      </p>

      <div className="flex items-center space-x-4 mb-6">
        <Tabs
          defaultValue={StorageViewType.MY_STORAGE}
          className="w-[400px]"
          value={storageViewType}
          onValueChange={(val) =>
            onStorageViewChange(val as unknown as StorageViewType)
          }
        >
          <TabsList
            className={cn(
              "grid w-full grid-cols-2",
              isRoleUser(role) ? "hidden" : ""
            )}
          >
            <TabsTrigger value={StorageViewType.MY_STORAGE}>
              My Storage
            </TabsTrigger>
            <TabsTrigger value={StorageViewType.USERS_STORAGE}>
              User's Storage
            </TabsTrigger>
          </TabsList>
        </Tabs>


        <div className="relative flex-grow">
          <Search className="absolute w-4 h-4 left-3 top-1/2 transform -translate-y-1/2 text-foreground/40" />
          <Input
            type="text"
            placeholder="Search..."
            className="pl-10 w-full"
            value={searchTerm}
            onChange={handleSearchChange}
            maxLength={100}
          />
        </div>

        <Select
          defaultValue={StorageViewSortKeys.CREATED_AT_DESC}
          value={storageViewSortType}
          onValueChange={onStorageViewSortChange}
        >
          <SelectTrigger className="w-[180px] border-0 shadow-none">
            <SelectValue placeholder="Sort by" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={StorageViewSortKeys.CREATED_AT_DESC}>
              Sort by <span className="text-primary">(Newest First)</span>
            </SelectItem>
            <SelectItem value={StorageViewSortKeys.CREATED_AT_ASC}>
              Sort by <span className="text-primary">(Oldest First)</span>
            </SelectItem>
            <SelectItem value={StorageViewSortKeys.STORAGE_NAME_ASC}>
              Sort by <span className="text-primary">(A-Z)</span>
            </SelectItem>
            <SelectItem value={StorageViewSortKeys.STORAGE_NAME_DESC}>
              Sort by <span className="text-primary">(Z-A)</span>
            </SelectItem>
          </SelectContent>
        </Select>
      </div>
    </div>
  );
};
