import { useState, useEffect, KeyboardEvent, ChangeEvent, useRef } from "react";
import { Download, Plus, Upload, X } from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { DialogTrigger } from "@radix-ui/react-dialog";
import {
  useCreateOrgInviteBatch,
  getOrgUsers,
} from "@/services/user-management";
import { toast } from "react-toastify";
import { useTokenAndOrgId } from "@/services/utils";
import { useActiveOrgProfile } from "@/hooks/useActiveOrgProfile";
import { TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Tabs } from "@radix-ui/react-tabs";

import Papa from "papaparse";
import { Alert, AlertDescription } from "@/components/ui/alert";

enum UserType {
  USER = "User",
  ADMIN = "Admin",
}

interface OrgUser {
  email: string;
  status: string; // "active" or "invited"
}

interface EmailValidationResult {
  validEmails: Array<{ email: string; type: UserType }>;
  existingCount: number;
  duplicateCount: number;
}

export const InviteUsers = () => {
  const { token, orgId } = useTokenAndOrgId();
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [userType, setUserType] = useState<UserType>(UserType.USER);
  const [emails, setEmails] = useState<
    Array<{ email: string; type: UserType }>
  >([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [existingUsers, setExistingUsers] = useState<OrgUser[]>([]);
  const { data: orgData } = useActiveOrgProfile();

  const [csvUploaded, setCsvUploaded] = useState(false);
  const [csvWarning, setCsvWarning] = useState<string>("");
  const fileInputRef = useRef<HTMLInputElement>(null);

  const storageLimitGB = orgData?.metadata.limits.org_per_user_storage_gb || 0;

  // Fetch all existing users when the dialog opens
  useEffect(() => {
    const fetchOrgUsers = async () => {
      if (!token || !orgId) {
        console.error("Missing token or orgId");
        toast.error("Missing token or organization ID.");
        return;
      }

      try {
        let pageNumber = 1;
        let fetchedAllUsers = false;
        let allUsers: OrgUser[] = [];

        while (!fetchedAllUsers) {
          const users = await getOrgUsers(
            token,
            orgId.toString(),
            100,
            pageNumber
          );

          if (users.length === 0) {
            fetchedAllUsers = true;
            break;
          }

          allUsers = [...allUsers, ...users];

          if (users.length < 100) {
            fetchedAllUsers = true;
          } else {
            pageNumber++;
          }
        }

        setExistingUsers(allUsers);
      } catch (error) {
        console.error("Failed to fetch existing users:", error);
        toast.error("Failed to load existing users.");
      }
    };

    if (isOpen) {
      fetchOrgUsers();
    }
  }, [isOpen, token, orgId]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    setErrorMessage("");
  };

  const handleInputKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      addEmail(inputValue);
    }
  };

  const addEmail = (email: string) => {
    const trimmedEmail = email.trim();

    if (!isValidEmail(trimmedEmail)) {
      setErrorMessage("Please enter a valid email address.");
      return;
    }

    const existingUser = existingUsers.find(
      (user) => user.email === trimmedEmail
    );

    if (existingUser) {
      if (existingUser.status === "active") {
        setErrorMessage(
          `${trimmedEmail} is already a member of the organization.`
        );
      } else {
        setEmails([...emails, { email: trimmedEmail, type: userType }]);
        setInputValue("");
        setErrorMessage("");
      }
    } else if (emails.map((e) => e.email).includes(trimmedEmail)) {
      setErrorMessage(`${trimmedEmail} is already in the invite list.`);
    } else {
      setEmails([...emails, { email: trimmedEmail, type: userType }]);
      setInputValue("");
      setErrorMessage("");
    }
  };

  const removeEmail = (emailToRemove: string) => {
    setEmails(emails.filter((email) => email.email !== emailToRemove));
  };

  const removeAllEmails = () => {
    resetCsvState()
  };

  const resetCsvState = () => {
    setEmails([]);
    setCsvWarning("");
    setCsvUploaded(false);
  };

  const isValidEmail = (email: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const { mutateAsync: batchInvite, isPending: sendingInvites } =
    useCreateOrgInviteBatch({
      onSuccess: () => {
        toast.success("Invitations sent successfully");
        setIsOpen(false);
        setEmails([]);
        setInputValue("");
      },
      onError: () => {
        toast.error("Failed to send invitations");
      },
    });

  const handleInvite = async () => {
    await batchInvite(
      emails.map((email) => ({ email: email.email, role: email.type }))
    );
  };

  const validateAndFilterEmails = (
    newEmails: Array<{ email: string; type: UserType }>
  ): EmailValidationResult => {
    const existingEmailSet = new Set(
      existingUsers
        .filter((user) => user.status === "active")
        .map((user) => user.email)
    );
    const currentEmailSet = new Set(emails.map((email) => email.email));
    const processedEmails = new Set<string>();
    const validEmails: Array<{ email: string; type: UserType }> = [];
    let existingCount = 0;
    let duplicateCount = 0;

    newEmails.forEach((entry) => {
      const email = entry.email.toLowerCase().trim();

      if (existingEmailSet.has(email)) {
        existingCount++;
      } else if (currentEmailSet.has(email) || processedEmails.has(email)) {
        duplicateCount++;
      } else {
        validEmails.push({ email, type: entry.type });
        processedEmails.add(email);
      }
    });

    return {
      validEmails,
      existingCount,
      duplicateCount,
    };
  };

  const handleCsvUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      // reset data
      resetCsvState();

      Papa.parse(file, {
        complete: (results) => {
          // Filter out invalid emails first
          const validEmailRows = results.data
            .filter((row: any) => row[0] && /\S+@\S+\.\S+/.test(row[0]))
            .map((row: any) => ({
              email: row[0].toLowerCase().trim(),
              type: row[1] === "Admin" ? UserType.ADMIN : UserType.USER,
            }));

          // Validate and filter emails
          const { validEmails, existingCount, duplicateCount } =
            validateAndFilterEmails(validEmailRows);

          // Construct warning message if needed
          const warningParts: string[] = [];

          if (existingCount > 0) {
            warningParts.push(
              `${existingCount} email${existingCount === 1 ? " was" : "s were"
              } omitted because they are already part of the organization`
            );
          }

          if (duplicateCount > 0) {
            warningParts.push(
              `${duplicateCount} duplicate email${duplicateCount === 1 ? " was" : "s were"
              } removed`
            );
          }

          const warningMessage = warningParts.join(" and ");
          setCsvWarning(warningMessage ? warningMessage + "." : "");

          // Add valid emails to the state
          setEmails([...emails, ...validEmails]);
          setCsvUploaded(true);
          if (fileInputRef.current) fileInputRef.current.value = "";
        },
      });
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button>
          <Plus className="w-4 h-4 mr-2" />
          Invite Users
        </Button>
      </DialogTrigger>

      <DialogContent className="sm:max-w-[525px]">
        <DialogHeader>
          <DialogTitle>Invite new users</DialogTitle>
        </DialogHeader>
        <Tabs defaultValue="manual" className="w-full">
          <TabsList className="grid w-full grid-cols-2">
            <TabsTrigger value="manual">Manual Entry</TabsTrigger>
            <TabsTrigger value="csv">CSV Upload</TabsTrigger>
          </TabsList>
          <TabsContent value="manual">
            <div className="space-y-4 py-4">
              <div className="flex items-center gap-2">
                <div className="relative flex-grow">
                  <Input
                    type="email"
                    placeholder="Enter email addresses"
                    value={inputValue}
                    onChange={handleInputChange}
                    onKeyDown={handleInputKeyDown}
                    className="pr-20"
                  />
                  {inputValue && (
                    <button
                      onClick={() => setInputValue("")}
                      className="absolute right-2 top-1/2 -translate-y-1/2 text-foreground/40 hover:text-foreground/60"
                    >
                      <X className="h-4 w-4" />
                    </button>
                  )}
                </div>
                <Select
                  defaultValue={UserType.USER}
                  value={userType}
                  onValueChange={(val) => setUserType(val as UserType)}
                >
                  <SelectTrigger className="w-[100px]">
                    <SelectValue placeholder="Select role" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value={UserType.USER}>User</SelectItem>
                    <SelectItem value={UserType.ADMIN}>Admin</SelectItem>
                  </SelectContent>
                </Select>
                <Button onClick={() => addEmail(inputValue)}>Add</Button>
              </div>
              {errorMessage && (
                <p className="text-destructive text-sm flex items-center gap-1">
                  <span className="bg-destructive/10 text-white rounded-full w-4 h-4 flex items-center justify-center text-xs">
                    !
                  </span>
                  {errorMessage}
                </p>
              )}
            </div>
          </TabsContent>
          <TabsContent value="csv">
            <div className="space-y-4 py-4">
              {/* Descriptive text (black text) */}
              <p className="text-sm text-foreground">
                You can download our sample CSV and pre-populate it with multiple email
                addresses so that you can bulk import large numbers of users. Download
                the file below.
              </p>

              {/* Download sample CSV button centered */}
              <div className="text-sm text-muted-foreground flex justify-center">
                <Download className="w-4 h-4 mr-1" />
                <a
                  href="/sample-invite-users.csv"
                  download
                  className="hover:underline"
                >
                  Download sample CSV
                </a>
              </div>

              {/* Upload CSV button */}
              <div className="flex items-center gap-2">
                <input
                  type="file"
                  accept=".csv"
                  onChange={handleCsvUpload}
                  className="hidden"
                  ref={fileInputRef}
                />
                <Button
                  variant="outline"
                  onClick={() => fileInputRef.current?.click()}
                  className="w-full"
                >
                  <Upload className="w-4 h-4 mr-2" />
                  Upload CSV
                </Button>
              </div>

              {/* Success or Warning messages */}
              {csvUploaded && (
                <p className="text-sm text-primary/70">
                  CSV file uploaded successfully!
                </p>
              )}
              {csvWarning && (
                <Alert variant="warning" className="mt-2">
                  <AlertDescription>{csvWarning}</AlertDescription>
                </Alert>
              )}
            </div>
          </TabsContent>
        </Tabs>
        <div className="space-y-4">
          <div className="space-y-2 max-h-[200px] overflow-y-auto">
            {emails.map((email, index) => (
              <div
                key={index}
                className="flex items-center justify-between bg-foreground/10 px-3 py-2 rounded-full"
              >
                <span className="text-sm w-full">{email.email}</span>
                <span className="text-xs text-foreground/50 pr-4">
                  {email.type}
                </span>
                <button
                  onClick={() => removeEmail(email.email)}
                  className="text-foreground/40 hover:text-foreground/60"
                >
                  <X className="h-4 w-4" />
                </button>
              </div>
            ))}
          </div>
          {emails.length > 0 && (
            <button
              onClick={removeAllEmails}
              className="text-destructive/50 text-sm text-right hover:underline w-fit ml-auto"
            >
              Remove all
            </button>
          )}
        </div>
        <p className="text-sm text-foreground/50">
          Invited users will have access to shared compute resources and{" "}
          {storageLimitGB}GB of Storage.
        </p>

        <DialogFooter>
          <Button
            className="w-full"
            onClick={handleInvite}
            disabled={!emails.length || sendingInvites}
          >
            {sendingInvites
              ? "Sending Invites..."
              : `Send Invites (${emails.length})`}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
