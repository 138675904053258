// TODO: fix the typescript errors
// @ts-nocheck
import { Link } from "@tanstack/react-router";
import React, { useState, useEffect, useRef } from "react";

export const NotFound = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [velocity, setVelocity] = useState({ dx: 3, dy: 3 });
  const [rotation, setRotation] = useState(0);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const containerRef = useRef(null);
  const animationRef = useRef();

  // Constants for element size
  const ELEMENT_WIDTH = 60;
  const ELEMENT_HEIGHT = 60;

  useEffect(() => {
    const updateDimensions = () => {
      if (containerRef.current) {
        setDimensions({
          width: containerRef.current.offsetWidth,
          height: containerRef.current.offsetHeight,
        });
        // Reset position when dimensions change
        setPosition((prev) => ({
          x: Math.min(prev.x, containerRef.current.offsetWidth - ELEMENT_WIDTH),
          y: Math.min(
            prev.y,
            containerRef.current.offsetHeight - ELEMENT_HEIGHT,
          ),
        }));
      }
    };

    // Initial setup
    updateDimensions();
    const resizeObserver = new ResizeObserver(updateDimensions);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, []);

  useEffect(() => {
    let rotationSpeed = Math.random() * 2 - 1;

    const animate = () => {
      setPosition((prev) => {
        let newX = prev.x + velocity.dx;
        let newY = prev.y + velocity.dy;
        let newDx = velocity.dx;
        let newDy = velocity.dy;

        // Handle horizontal bouncing
        if (newX <= 0 || newX >= dimensions.width - ELEMENT_WIDTH) {
          newDx = -velocity.dx;
          rotationSpeed = Math.random() * 2 - 1;
        }

        // Handle vertical bouncing
        if (newY <= 0 || newY >= dimensions.height - ELEMENT_HEIGHT) {
          newDy = -velocity.dy;
          rotationSpeed = Math.random() * 2 - 1;
        }

        // Update velocity if changed
        if (newDx !== velocity.dx || newDy !== velocity.dy) {
          setVelocity({ dx: newDx, dy: newDy });
        }

        // Keep element within bounds
        newX = Math.max(0, Math.min(newX, dimensions.width - ELEMENT_WIDTH));
        newY = Math.max(0, Math.min(newY, dimensions.height - ELEMENT_HEIGHT));

        return { x: newX, y: newY };
      });

      // Update rotation
      setRotation((prev) => prev + rotationSpeed);

      animationRef.current = requestAnimationFrame(animate);
    };

    animationRef.current = requestAnimationFrame(animate);

    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [dimensions, velocity]);

  return (
    <div
      ref={containerRef}
      className="w-screen h-screen bg-primary/5 relative overflow-hidden"
    >
      <div className="fixed left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] flex flex-col gap-1 items-center">
        <p className="text-4xl font-mono font-bold text-primary">404</p>
        <p className="text-2xl font-mono font-medium text-primary">
          Page not found
        </p>
        <Link
          to="/"
          className="text-lg mt-5 font-mono font-medium text-sky-500 underline decoration-wavy"
        >
          Back to home
        </Link>
      </div>

      <div
        className="absolute w-20 h-20 select-none"
        style={{
          transform: `translate(${position.x}px, ${position.y}px) rotate(${rotation}deg)`,
          willChange: "transform",
        }}
      >
        <img src="/assets/image/404.png" />
      </div>
    </div>
  );
};
